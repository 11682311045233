export const CONST_COLOR_TEXT = '#FFFFFF';
export const CONST_COLOR_PRIMARY = '#08D1B8';
export const CONST_COLOR_PRIMARY_DARKER = '#15b39f';
export const CONST_COLOR_SECONDARY = '#CAC9E4';
export const CONST_COLOR_TEXT_CONTRAST = '#341F62';
export const CONST_COLOR_TEXT_CONTRAST_LIGHTER = '#9A8ECE';
export const CONST_COLOR_GENERATE_SITE = '#6F3BCC';

export const CONST_URL_WAITLIST = '';
export const CONST_URL_DISCORD = 'https://discord.com/invite/Va9XUxJkV5';
export const CONST_URL_TWITTER = 'https://twitter.com/ego_ai_';

export const CONST_URL_SPLINE_SCENE = 'https://prod.spline.design/cKXKR4bh1uL6d9aU/scene.splinecode';
export const CONST_URL_ZAPIER_HOOK = 'https://hooks.zapier.com/hooks/catch/14501530/3zq81tt/';
