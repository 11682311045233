import { Box, Button, TextField, Typography } from "@mui/material";
import { CONST_COLOR_PRIMARY, CONST_COLOR_PRIMARY_DARKER, CONST_URL_ZAPIER_HOOK } from "./Constants";
import React, { useState } from "react";

interface SignUpWaitlistPopupProps {
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const SignUpWaitlistPopup: React.FC<SignUpWaitlistPopupProps> = ({ setShowPopup }) => {

  const [userEmail, setUserEmail] = useState('');
  const [hasUserSubmittedSuccessfully, setHasUserSubmittedSuccessfully] = useState(false);
  const [showError, setShowError] = useState(false);

  const sendToZapier = async (email: string) => {
    const url = CONST_URL_ZAPIER_HOOK;
    const data = {
      email: { "email": email }
    };
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    });
    return response.json();
  }

  return (
    <Box sx={{ width: '400px', maxWidth: 'calc(90% - 48px)', backgroundColor: 'white', borderRadius: '24px', flexDirection: 'column', display: 'flex', alignItems: 'center', padding: '24px' }}>
      
      {!hasUserSubmittedSuccessfully ? (<>

        <Typography sx={{ fontFamily: 'Ubuntu', fontWeight: 'bold', fontSize: '20px' }}>{`Get Early Access`}</Typography>
        <Box sx={{ height: '4px' }} />     
        <Typography sx={{ fontFamily: 'Ubuntu-Light', fontWeight: 'bold', fontSize: '17px', textAlign: 'center' }}>{`Sign up for updates and be the first to know when we launch!`}</Typography>      
        <Box sx={{ height: '16px' }} />

        <TextField  variant="outlined" fullWidth 
          label="Email"
          value={userEmail}
          autoComplete="off"
          onChange={(e) => setUserEmail(e.target.value)}
          InputLabelProps={{ style: { fontFamily: 'Ubuntu' } }}
          InputProps={{ style: { fontFamily: 'Ubuntu', fontWeight: 'bold', color: CONST_COLOR_PRIMARY_DARKER } }}
        />   

        <Box sx={{ height: '16px' }} />

        <Button onClick={() => {

          sendToZapier(userEmail)
            .then((response) => {
              setHasUserSubmittedSuccessfully(true);
            })
            .catch((error) => {
              setShowError(true);
            });

        }} variant='contained' disableElevation sx={{ ':hover': { backgroundColor: CONST_COLOR_PRIMARY }, textTransform: 'none', fontFamily: 'Ubuntu', fontSize: '18px', backgroundColor: CONST_COLOR_PRIMARY_DARKER, borderRadius: '30px', fontWeight: 'bold', paddingLeft: '28px', paddingRight: '28px' }}>
          {`Join the Waitlist`}
        </Button>

        {showError ? (<>
          <Box sx={{ height: '16px' }} />
          <Typography sx={{ fontFamily: 'Ubuntu-Light', fontWeight: 'bold', fontSize: '17px', textAlign: 'center', color: 'red' }}>{`An error occurred. Please try again.`}</Typography>
        </>) : null}

      </>) : (<>
        
        <Typography sx={{ fontFamily: 'Ubuntu', fontWeight: 'bold', fontSize: '20px' }}>{`Thank you!`}</Typography>
        <Box sx={{ height: '4px' }} />     
        <Typography sx={{ fontFamily: 'Ubuntu-Light', fontWeight: 'bold', fontSize: '17px', textAlign: 'center' }}>{`We'll keep you updated on our progress.`}</Typography>      
        <Box sx={{ height: '16px' }} />

        <Button onClick={() => {
          setShowPopup(false);
        }} variant='contained' disableElevation sx={{ ':hover': { backgroundColor: CONST_COLOR_PRIMARY }, textTransform: 'none', fontFamily: 'Ubuntu', fontSize: '18px', backgroundColor: CONST_COLOR_PRIMARY_DARKER, borderRadius: '30px', fontWeight: 'bold', paddingLeft: '28px', paddingRight: '28px' }}>
          {`Close`}
        </Button>

      </>)}

    </Box>
  )

};

export default SignUpWaitlistPopup;
