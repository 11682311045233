interface YcEmbedProps {
  width: number;
}


const YcEmbed: React.FC<YcEmbedProps> = ({ width }) => {
  return (
    <a 
      href='https://www.ycombinator.com/launches/KeD-ego-an-ai-native-3d-simulation-engine-getting-us-closer-to-the-matrix-one-pixel-at-a-time' 
      target="_blank"
      style={{ display: 'flex', alignItems: 'center'  }}
    >
      <img src='https://www.ycombinator.com/launches/KeD-ego-an-ai-native-3d-simulation-engine-getting-us-closer-to-the-matrix-one-pixel-at-a-time/upvote_embed.svg' width={`${width}px`} alt='Launch YC: ego - an ai-native 3d simulation engine: :invasor_del_espacio: getting us closer to the matrix, one pixel at a time'/ >  
    </a>
  )
};

export default YcEmbed;