import React, { useEffect, useState } from 'react';
import { Box, Button, Collapse, Fade, Grow, IconButton, Typography } from '@mui/material';
import logo from '../assets/images/ego-logo.svg'
import discordLogo from '../assets/images/discord-logo.svg'
import twitterLogo from '../assets/images/twitter-logo.svg'
import aiStar from '../assets/images/ai-star.svg'
import { CONST_COLOR_GENERATE_SITE, CONST_COLOR_PRIMARY, CONST_COLOR_PRIMARY_DARKER, CONST_COLOR_SECONDARY, CONST_COLOR_TEXT, CONST_COLOR_TEXT_CONTRAST, CONST_COLOR_TEXT_CONTRAST_LIGHTER, CONST_URL_DISCORD, CONST_URL_SPLINE_SCENE, CONST_URL_TWITTER, CONST_URL_WAITLIST } from '../common/Constants';
import { ReactTyped } from 'react-typed';
import Spline from '@splinetool/react-spline';
import SignUpWaitlistPopup from '../common/SignUpWaitlistPopup';
import YcEmbed from '../common/YcEmbed';

const LandingPage = () => {

  const [hasGenerateSiteClicked, setHasGenerateSiteClicked] = useState(false);
  const [isShowWaitlist, setIsShowWaitlist] = useState(false);

  const [animationFlag_EgoLogo, setAnimationFlag_EgoLogo] = useState(false);
  const [animationFlag_EgoLogo_Done, setAnimationFlag_EgoLogo_Done] = useState(false);
  const [animationFlag_ControlsOutline, setAnimationFlag_ControlsOutline] = useState(false);
  const [animationFlag_ControlsOutline_Done, setAnimationFlag_ControlsOutline_Done] = useState(false);
  const [animationFlag_Title_1, setAnimationFlag_Title_1] = useState(false);
  const [animationFlag_Title_1_Done, setAnimationFlag_Title_1_Done] = useState(false);
  const [animationFlag_Title_2, setAnimationFlag_Title_2] = useState(false);
  const [animationFlag_Title_2_Done, setAnimationFlag_Title_2_Done] = useState(false);
  const [animationFlag_Title_3, setAnimationFlag_Title_3] = useState(false);
  const [animationFlag_Title_3_Done, setAnimationFlag_Title_3_Done] = useState(false);
  const [animationFlag_Title_4, setAnimationFlag_Title_4] = useState(false);
  const [animationFlag_Title_4_Done, setAnimationFlag_Title_4_Done] = useState(false);
  const [animationFlag_Subtitle, setAnimationFlag_Subtitle] = useState(false);
  const [animationFlag_Subtitle_Done, setAnimationFlag_Subtitle_Done] = useState(false);
  const [animationFlag_CallsToAction_Waitlist, setAnimationFlag_CallsToAction_Waitlist] = useState(false);
  const [animationFlag_CallsToAction_Waitlist_Done, setAnimationFlag_CallsToAction_Waitlist_Done] = useState(false);
  const [animationFlag_CallsToAction_Discord, setAnimationFlag_CallsToAction_Discord] = useState(false);
  const [animationFlag_CallsToAction_Discord_Done, setAnimationFlag_CallsToAction_Discord_Done] = useState(false);
  const [animationFlag_CallsToAction_Twitter, setAnimationFlag_CallsToAction_Twitter] = useState(false);
  const [animationFlag_CallsToAction_Twitter_Done, setAnimationFlag_CallsToAction_Twitter_Done] = useState(false);

  useEffect(() => { if (hasGenerateSiteClicked) setAnimationFlag_EgoLogo(true) }, [hasGenerateSiteClicked]);
  useEffect(() => { if (animationFlag_EgoLogo_Done) setAnimationFlag_ControlsOutline(true) }, [animationFlag_EgoLogo_Done]);
  useEffect(() => { if (animationFlag_ControlsOutline_Done) setAnimationFlag_Title_1(true) }, [animationFlag_ControlsOutline_Done]);
  useEffect(() => { if (animationFlag_Title_1_Done) setAnimationFlag_Title_2(true) }, [animationFlag_Title_1_Done]);
  useEffect(() => { if (animationFlag_Title_2_Done) setAnimationFlag_Title_3(true) }, [animationFlag_Title_2_Done]);
  useEffect(() => { if (animationFlag_Title_3_Done) setAnimationFlag_Title_4(true) }, [animationFlag_Title_3_Done]);
  useEffect(() => { if (animationFlag_Title_4_Done) setAnimationFlag_Subtitle(true) }, [animationFlag_Title_4_Done]);
  useEffect(() => { if (animationFlag_Subtitle_Done) setAnimationFlag_CallsToAction_Waitlist(true) }, [animationFlag_Subtitle_Done]);
  useEffect(() => { if (animationFlag_CallsToAction_Waitlist_Done) setAnimationFlag_CallsToAction_Discord(true) }, [animationFlag_CallsToAction_Waitlist_Done]);
  useEffect(() => { if (animationFlag_CallsToAction_Discord_Done) setAnimationFlag_CallsToAction_Twitter(true) }, [animationFlag_CallsToAction_Discord_Done]);

  return (
    <>

      {/* Background Spline scene */}
      <Box sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: '#5b1fcc' }}>
        <Spline scene={CONST_URL_SPLINE_SCENE} />
      </Box>
      
      {/* Ego Logo */}
      <Fade in={animationFlag_EgoLogo} timeout={1750} onEntered={() => setAnimationFlag_EgoLogo_Done(true)}>
        <Box sx={{ position: 'absolute', top: 24, left: 24, right: 24, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <img src={logo} height={'40px'}/>
          <Box sx={{ marginLeft: 'auto', marginRight: '0px' }}>
            <YcEmbed width={210} />
          </Box>
        </Box>
      </Fade>

      {/* Text and UI controls */}
      <Fade in={animationFlag_ControlsOutline} timeout={1500} onEntered={() => setAnimationFlag_ControlsOutline_Done(true)}>

        <Box sx={{ position: 'absolute', minHeight: '290px', top: 84, left: 16, right: 16, padding: '24px', borderRadius: '30px', backgroundColor: 'rgba(0, 0, 0, 0.2)', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          
          <Box sx={{  }}>

            {/* Headline */}
            <Typography sx={{ paddingRight: '10px', color: CONST_COLOR_PRIMARY, fontSize: '32px', fontWeight: 700, fontFamily: 'Ubuntu', textAlign: 'left' }}>
              {animationFlag_Title_1 && (
                <ReactTyped
                  strings={['Simulated ']}
                  typeSpeed={32}
                  onComplete={() => setAnimationFlag_Title_1_Done(true)}
                  showCursor={false}
                />
              )}
            </Typography>

            <Typography sx={{ paddingRight: '10px', color: CONST_COLOR_TEXT, fontSize: '32px', fontWeight: 700, fontFamily: 'Ubuntu', textAlign: 'left' }}>
              {animationFlag_Title_2 && (
                <ReactTyped
                  strings={['Communities in']}
                  typeSpeed={32}
                  onComplete={() => setAnimationFlag_Title_2_Done(true)}
                  showCursor={false}
                />
              )}
            </Typography>

            <Typography sx={{ color: CONST_COLOR_TEXT, fontSize: '29px', fontWeight: 700, fontFamily: 'Ubuntu', textAlign: 'left' }}>
              <Typography display={'inline'} sx={{ color: CONST_COLOR_PRIMARY, fontSize: '32px', fontWeight: 700, fontFamily: 'Ubuntu', textAlign: 'left' }}>
                {animationFlag_Title_3 && (
                  <ReactTyped
                    strings={['Synthetic&nbsp;']}
                    typeSpeed={32}
                    onComplete={() => setAnimationFlag_Title_3_Done(true)}
                    showCursor={false}
                  />
                )}
              </Typography>
              {animationFlag_Title_4 && (
                <ReactTyped
                  strings={['Worlds']}
                  typeSpeed={32}
                  onComplete={() => setAnimationFlag_Title_4_Done(true)}
                  showCursor={false}
                />
              )}
            </Typography>

          </Box>

          <Box sx={{ height: '10px' }} />

          {/* Subheadline */}
          {animationFlag_Subtitle && (
            <Typography sx={{ color: CONST_COLOR_SECONDARY, fontSize: '14px', textAlign: 'left', fontFamily: 'Ubuntu-Light', fontWeight: 700}}>
              <ReactTyped
                strings={['Create and share worlds populated with human-like generative agents on Ego']}
                typeSpeed={16}
                onComplete={() => setAnimationFlag_Subtitle_Done(true)}
              />
            </Typography>
          )}

          <Box sx={{ height: '22px' }} />

          {/* Calls to Action */}
          <Box sx={{ marginTop: 'auto', marginBottom: 0, display: 'flex', flexDirection: 'row' }}>
            
            <Fade in={animationFlag_CallsToAction_Waitlist} timeout={750} onEntered={() => setAnimationFlag_CallsToAction_Waitlist_Done(true)}>
              <Button onClick={() => setIsShowWaitlist(true)} variant='contained' disableElevation sx={{ ':hover': { backgroundColor: CONST_COLOR_PRIMARY_DARKER }, textTransform: 'none', fontFamily: 'Ubuntu', fontSize: '16px', backgroundColor: CONST_COLOR_PRIMARY, borderRadius: '30px', fontWeight: 'bold', paddingLeft: '18px', paddingRight: '18px' }}>
                {`Join Waitlist`}
              </Button>
            </Fade>

            <Box sx={{ width: '12px' }} />

            <Fade in={animationFlag_CallsToAction_Discord} timeout={750} onEntered={() => setAnimationFlag_CallsToAction_Discord_Done(true)}>
              <IconButton onClick={() => window.open(CONST_URL_DISCORD, '_blank')} sx={{ backgroundColor: CONST_COLOR_TEXT_CONTRAST, height: '52px', width: '52px', ':hover': { backgroundColor: CONST_COLOR_TEXT_CONTRAST_LIGHTER } }}>
                <img src={discordLogo} height='32px' width='32px' />
              </IconButton>
            </Fade>

            <Box sx={{ width: '12px' }} />
            
            <Fade in={animationFlag_CallsToAction_Twitter} timeout={750} onEntered={() => setAnimationFlag_CallsToAction_Twitter_Done(true)}>
              <IconButton onClick={() => window.open(CONST_URL_TWITTER, '_blank')} sx={{ backgroundColor: CONST_COLOR_TEXT_CONTRAST, height: '52px', width: '52px', ':hover': { backgroundColor: CONST_COLOR_TEXT_CONTRAST_LIGHTER } }}>
                <img src={twitterLogo} height='32px' width='32px' />
              </IconButton>
            </Fade>

          </Box>

        </Box>

      </Fade>

      {/* Generate Site Button */}
      {!hasGenerateSiteClicked && (
        <Box sx={{ position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'black', zIndex: 1 }}>
          <Grow in={true} timeout={1500}>
            <Button variant='contained' disableElevation onClick={() => setHasGenerateSiteClicked(true)} sx={{ 
              textTransform: 'none', fontFamily: 'Ubuntu', fontSize: '20px', backgroundColor: CONST_COLOR_GENERATE_SITE, borderRadius: '30px', fontWeight: 'bold', paddingLeft: '24px', paddingRight: '28px',
              ':hover': { backgroundColor: CONST_COLOR_PRIMARY },
              ":active": { transform: { scale: 0.8 } }
            }}>
              <img src={aiStar} height='40px' width='40px' />
              {`Generate Site`}
            </Button>
          </Grow>
        </Box>
      )}

      {/* Waitlist Popup */}
      {isShowWaitlist && (
        <Box onTouchEnd={(e) => {
          if (e.target === e.currentTarget) setIsShowWaitlist(false)
        }} sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <SignUpWaitlistPopup setShowPopup={setIsShowWaitlist} />
        </Box>
      )}

    </>
  );
}

export default LandingPage;
