import { createTheme } from "@mui/material";
import { CONST_COLOR_PRIMARY_DARKER } from "./Constants";

const theme = createTheme({
  palette: {
    primary: {
      main: CONST_COLOR_PRIMARY_DARKER,
      contrastText: '#fff',
    }
  },
});

export default theme;