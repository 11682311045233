import { Box, ThemeProvider, useMediaQuery } from "@mui/material";
import LandingPageDesktop from "./desktop/LandingPage";
import LandingPageMobile from "./mobile/LandingPage";
import ReactGA from 'react-ga';
import theme from "./common/theme";
import { inject } from '@vercel/analytics';
 
inject();

ReactGA.initialize('G-JSLCLJ0PGK');

function App() {

  const isMobile = useMediaQuery('(max-width: 600px)');

  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="App">

      <ThemeProvider theme={theme}>
        <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
          {!isMobile ? (
            <LandingPageDesktop />
          ) : (
            <LandingPageMobile />
          )}
        </Box>
      </ThemeProvider>

    </div>
  );
}

export default App;
